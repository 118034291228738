import React, { useState } from 'react';
import './BookingForm.css';

const BookingForm = () => {
  const [patientName, setPatientName] = useState('');
  const [doctor, setDoctor] = useState('');
  const [date, setDate] = useState('');
  const [time, setTime] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle booking logic here
    alert(`Booking confirmed for ${patientName} with Dr. ${doctor} on ${date} at ${time}`);
  };

  return (
    <form className="booking-form" onSubmit={handleSubmit}>
      <label>
        Patient Name:
        <input type="text" value={patientName} onChange={(e) => setPatientName(e.target.value)} required />
      </label>
      <label>
        Select Doctor:
        <select value={doctor} onChange={(e) => setDoctor(e.target.value)} required>
          <option value="">Select...</option>
          <option value="Smith">Dr. Smith</option>
          <option value="Johnson">Dr. Johnson</option>
          <option value="Williams">Dr. Williams</option>
        </select>
      </label>
      <label>
        Date:
        <input type="date" value={date} onChange={(e) => setDate(e.target.value)} required />
      </label>
      <label>
        Time:
        <input type="time" value={time} onChange={(e) => setTime(e.target.value)} required />
      </label>
      <button type="submit">Book Appointment</button>
    </form>
  );
};

export default BookingForm;